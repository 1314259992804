import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from 'linaria'

import RangeSlider from '../components/RangeSlider'

export default ({
  languages,
  initialSize,
  initialLineHeight,
  initialWeight,
}) => {
  const [size, setSize] = useState(initialSize)
  const [lineHeight, setLineHeight] = useState(initialLineHeight)
  const [weight, setWeight] = useState(initialWeight)
  const udhr = useStaticQuery(graphql`
    fragment Data on UdhrJson {
      langName: language
      article {
        para
      }
    }
    query UDHRQuery {
      nl: udhrJson(lang: { eq: "nl" }) {
        ...Data
      }
      sv: udhrJson(lang: { eq: "sv" }) {
        ...Data
      }
      fi: udhrJson(lang: { eq: "fi" }) {
        ...Data
      }
      it: udhrJson(lang: { eq: "it" }) {
        ...Data
      }
      hu: udhrJson(lang: { eq: "hu" }) {
        ...Data
      }
      fr: udhrJson(lang: { eq: "fr" }) {
        ...Data
      }
      cs: udhrJson(lang: { eq: "cs" }) {
        ...Data
      }
      pl: udhrJson(lang: { eq: "pl" }) {
        ...Data
      }
      tr: udhrJson(lang: { eq: "tr" }) {
        ...Data
      }
      is: udhrJson(lang: { eq: "is" }) {
        ...Data
      }
      lv: udhrJson(lang: { eq: "lv" }) {
        ...Data
      }
      ro: udhrJson(lang: { eq: "ro" }) {
        ...Data
      }
      es: udhrJson(lang: { eq: "es" }) {
        ...Data
      }
    }
  `)

  return (
    <div className="stack--small">
      {/* <div className="stack--small">
        <select
          defaultValue={weight}
          onChange={e => {
            setWeight(e.target.value)
          }}
        >
          <option value="normal">Normaal</option>
          <option value="bold">Vet</option>
        </select>
        <RangeSlider
          range={[6, 20]}
          value={size}
          onInput={e => {
            setSize(e.target.value)
          }}
        />
        <RangeSlider
          range={[1, 2]}
          step={0.05}
          value={lineHeight}
          onInput={e => {
            setLineHeight(e.target.value)
          }}
        />
        <span>
          {size} pt. / {lineHeight}
        </span>
      </div> */}
      <div
        className={gridStyle}
        style={{
          fontSize: `${size}pt`,
          lineHeight: lineHeight,
          fontWeight: weight,
        }}
      >
        {languages.map(language => {
          const { article, langName } = udhr[language]
          return (
            <div key={language}>
              <h3
                style={{
                  fontWeight: `normal`,
                  fontSize: `${size}pt`,
                  margin: `0 0 ${lineHeight / 2}em 0`,
                }}
              >
                {langName}
              </h3>
              <p
                style={{
                  maxWidth: `75ch`,
                  margin: 0,
                }}
              >
                {article[0].para} {article[1].para} {article[2].para}{' '}
                {article[3].para}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const gridStyle = css`
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(45ch, 1fr));
  grid-auto-rows: 1fr;
`
