import React from 'react'
import { styled } from 'linaria/react'

export default ({ onInput, range, value, step }) => (
  <RangeSlider
    type="range"
    min={range[0]}
    max={range[1]}
    step={step ? step : 1}
    value={value}
    onInput={onInput}
  />
)

const RangeSlider = styled.input`
  --color-track: var(--color-text);
  --color-thumb: var(--color-background);
  --track-height: 1px;
  --thumb-size: 0.8rem;

  width: 100%;
  height: 1rem;
  -webkit-appearance: none;
  cursor: pointer;
  /* display: block; */

  &:hover {
    ::-webkit-slider-runnable-track,
    ::-moz-range-track {
      background: var(--color-accent);
    }
  }

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: var(--track-height);
    background: var(--color-track);
  }
  &::-webkit-slider-thumb {
    height: var(--thumb-size);
    width: var(--thumb-size);
    border-radius: 100%;
    background: var(--color-thumb);
    cursor: grab;
    -webkit-appearance: none;
    margin-top: -7px;
    border: none;
    box-shadow: none;
    border: 1px solid var(--color-track);
    &:active {
      cursor: grabbing;
    }
  }
  &:focus::-webkit-slider-runnable-track {
    background: #c4c4c4;
  }
  &::-moz-range-track {
    width: 100%;
    height: var(--track-height);
    background: var(--color-track);
  }
  &::-moz-range-thumb {
    height: var(--thumb-size);
    width: var(--thumb-size);
    border-radius: 100%;
    background: var(--color-thumb);
    cursor: grab;
    box-shadow: none;
    border: 1px solid var(--color-track);
  }
  &:focus::-moz-range-thumb:active {
    cursor: grabbing;
  }
  &:focus::-moz-range-thumb,
  &::-moz-range-thumb:hover {
    background: var(--color-text);
  }
`
