import theme from './theme'
import { css } from 'linaria'
import { regular, bold } from './mercator'

const base64 = `data:application/x-font-woff2;charset=utf-8;base64,`
const fontRegular = base64 + regular
const fontBold = base64 + bold

const breakpoints = [0, 600, 900, 1200]

export const globalStyle = css`
  :global() {
    :root {
      --root-unit: 10px;
      @media screen and (min-width: ${breakpoints[1]}px) {
        --root-unit: 11px;
      }
      @media screen and (min-width: ${breakpoints[2]}px) {
        --root-unit: 12px;
      }
      --color-background: ${theme.colors.background};
      --color-text: ${theme.colors.text};
      --color-accent: ${theme.colors.primary};
      --size-0: ${theme.sizes[0]};
      --size-1: ${theme.sizes[1]};
      --size-2: ${theme.sizes[2]};
      --size-3: ${theme.sizes[3]};
      --size-4: ${theme.sizes[4]};
      --size-5: ${theme.sizes[5]};
      --size-6: ${theme.sizes[6]};
      --size-7: ${theme.sizes[7]};
      --size-8: ${theme.sizes[8]};
      --size-9: ${theme.sizes[9]};
      --size-10: ${theme.sizes[10]};
      --content-max-width: 120rem;
    }

    * {
      max-width: 60ch;
      box-sizing: border-box;
    }

    html,
    body,
    div,
    input,
    header,
    nav,
    main,
    section,
    footer,
    img {
      max-width: none;
      margin: 0;
    }

    .stack--s > * + * {
      margin-top: ${theme.sizes[2]};
    }

    .stack--m > * + * {
      margin-top: ${theme.sizes[6]};
    }

    .stack--l > * + * {
      margin-top: ${theme.sizes[8]};
    }

    .stack--xl > * + * {
      margin-top: ${theme.sizes[9]};
    }

    .stack--xxl > * + * {
      margin-top: ${theme.sizes[9]};
    }

    @font-face {
      font-family: 'Mercator';
      font-weight: normal;
      src: url(${fontRegular});
      font-display: block;
    }
    @font-face {
      font-family: 'Mercator';
      font-weight: bold;
      src: url(${fontBold});
      font-display: block;
    }

    img {
      max-width: 100%;
    }

    figure {
      max-width: 75ch;
    }

    html {
      font-family: 'Mercator';
      font-weight: normal;
      color: var(--color-text);
      font-size: var(--root-unit);
    }

    .container {
      padding: 0 var(--size-3) 0 0;
      max-width: var(--content-max-width);
    }

    h1 {
      font-size: 4rem;
      @media screen and (min-width: ${breakpoints[1]}px) {
        font-size: 6rem;
      }
      margin: 0;
      margin-bottom: 1rem;
      font-weight: normal;
      line-height: 1;
    }

    h2 {
      font-size: var(--size-4);
      font-weight: normal;
      margin: var(--size-5) 0 var(--size-4) 0;
    }
  }
`
