export default [
  `A`,
  `B`,
  `C`,
  `D`,
  `E`,
  `F`,
  `G`,
  `H`,
  `I`,
  `J`,
  `K`,
  `L`,
  `M`,
  `N`,
  `O`,
  `P`,
  `Q`,
  `R`,
  `S`,
  `T`,
  `U`,
  `V`,
  `W`,
  `X`,
  `Y`,
  `Z`,
  `À`,
  `Á`,
  `Â`,
  `Ã`,
  `Ä`,
  `Å`,
  `Æ`,
  `Ç`,
  `È`,
  `É`,
  `Ê`,
  `Ë`,
  `Ì`,
  `Í`,
  `Î`,
  `Ï`,
  `Ð`,
  `Ñ`,
  `Ò`,
  `Ó`,
  `Ô`,
  `Õ`,
  `Ö`,
  `Ø`,
  `Ù`,
  `Ú`,
  `Û`,
  `Ü`,
  `Ý`,
  `Þ`,
  `Ā`,
  `Ă`,
  `Ą`,
  `Ć`,
  `Ĉ`,
  `Ċ`,
  `Č`,
  `Ď`,
  `Đ`,
  `Ē`,
  `Ĕ`,
  `Ė`,
  `Ę`,
  `Ě`,
  `Ĝ`,
  `Ğ`,
  `Ġ`,
  `Ģ`,
  `Ĥ`,
  `Ħ`,
  `Ĩ`,
  `Ī`,
  `Ĭ`,
  `Į`,
  `İ`,
  `Ĵ`,
  `Ķ`,
  `Ĺ`,
  `Ļ`,
  `Ľ`,
  `Ŀ`,
  `Ł`,
  `Ń`,
  `Ņ`,
  `Ň`,
  `Ŋ`,
  `Ō`,
  `Ŏ`,
  `Ő`,
  `Œ`,
  `Ŕ`,
  `Ŗ`,
  `Ř`,
  `Ś`,
  `Ŝ`,
  `Ş`,
  `Š`,
  `Ţ`,
  `Ť`,
  `Ŧ`,
  `Ũ`,
  `Ū`,
  `Ŭ`,
  `Ů`,
  `Ű`,
  `Ų`,
  `Ŵ`,
  `Ŷ`,
  `Ÿ`,
  `Ź`,
  `Ż`,
  `Ž`,
  `Ə`,
  `Ǻ`,
  `Ǽ`,
  `Ǿ`,
  `Ș`,
  `Ț`,
  `Δ`,
  `Ω`,
  `Ẁ`,
  `Ẃ`,
  `Ẅ`,
  `Ạ`,
  `Ẹ`,
  `Ẽ`,
  `Ị`,
  `Ọ`,
  `Ụ`,
  `Ỳ`,
  `Ỹ`,
  `a`,
  `b`,
  `c`,
  `d`,
  `e`,
  `f`,
  `g`,
  `h`,
  `i`,
  `j`,
  `k`,
  `l`,
  `m`,
  `n`,
  `o`,
  `p`,
  `q`,
  `r`,
  `s`,
  `t`,
  `u`,
  `v`,
  `w`,
  `x`,
  `y`,
  `z`,
  `ß`,
  `à`,
  `á`,
  `â`,
  `ã`,
  `ä`,
  `å`,
  `æ`,
  `ç`,
  `è`,
  `é`,
  `ê`,
  `ë`,
  `ì`,
  `í`,
  `î`,
  `ï`,
  `ð`,
  `ñ`,
  `ò`,
  `ó`,
  `ô`,
  `õ`,
  `ö`,
  `ø`,
  `ù`,
  `ú`,
  `û`,
  `ü`,
  `ý`,
  `þ`,
  `ÿ`,
  `ā`,
  `ă`,
  `ą`,
  `ć`,
  `ĉ`,
  `ċ`,
  `č`,
  `ď`,
  `đ`,
  `ē`,
  `ĕ`,
  `ė`,
  `ę`,
  `ě`,
  `ĝ`,
  `ğ`,
  `ġ`,
  `ģ`,
  `ĥ`,
  `ħ`,
  `ĩ`,
  `ī`,
  `ĭ`,
  `į`,
  `ı`,
  `ĵ`,
  `ķ`,
  `ĸ`,
  `ĺ`,
  `ļ`,
  `ľ`,
  `ŀ`,
  `ł`,
  `ń`,
  `ņ`,
  `ň`,
  `ŋ`,
  `ō`,
  `ŏ`,
  `ő`,
  `œ`,
  `ŕ`,
  `ŗ`,
  `ř`,
  `ś`,
  `ŝ`,
  `ş`,
  `š`,
  `ţ`,
  `ť`,
  `ŧ`,
  `ũ`,
  `ū`,
  `ŭ`,
  `ů`,
  `ű`,
  `ų`,
  `ŵ`,
  `ŷ`,
  `ź`,
  `ż`,
  `ž`,
  `ƒ`,
  `ǻ`,
  `ǽ`,
  `ǿ`,
  `ș`,
  `ț`,
  `ȷ`,
  `ə`,
  `μ`,
  `π`,
  `ẁ`,
  `ẃ`,
  `ẅ`,
  `ạ`,
  `ẹ`,
  `ẽ`,
  `ị`,
  `ọ`,
  `ụ`,
  `ỳ`,
  `ỹ`,
  `0`,
  `1`,
  `2`,
  `3`,
  `4`,
  `5`,
  `6`,
  `7`,
  `8`,
  `9`,
  `¼`,
  `½`,
  `¾`,
  `⅛`,
  `⅜`,
  `⅝`,
  `⅞`,
  `$`,
  `+`,
  `<`,
  `=`,
  `>`,
  `^`,
  `|`,
  `~`,
  `¢`,
  `£`,
  `¤`,
  `¥`,
  `¦`,
  `©`,
  `¬`,
  `®`,
  `¯`,
  `°`,
  `±`,

  `×`,
  `÷`,
  `˚`,
  `₤`,
  `€`,
  `₽`,
  `№`,
  `℗`,
  `™`,
  `∂`,
  `∏`,
  `∑`,
  `−`,
  `√`,
  `∞`,
  `∫`,
  `≈`,
  `≠`,
  `≤`,
  `≥`,
  `◊`,
  `!`,
  `"`,
  `#`,
  `%`,
  `&`,
  `'`,
  `(`,
  `)`,
  `*`,
  `,`,
  `-`,
  `.`,
  `/`,
  `:`,
  `;`,
  `?`,
  `@`,
  `[`,
  `\\`,
  `]`,
  `_`,
  `{`,
  `}`,
  `¡`,
  `§`,
  `«`,
  `¶`,
  `·`,
  `»`,
  `¿`,
  `–`,
  `—`,
  `‘`,
  `’`,
  `‚`,
  `“`,
  `”`,
  `„`,
  `†`,
  `‡`,
  `•`,
  `…`,
  `‰`,
  `‹`,
  `›`,
]
