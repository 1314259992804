import React, { useState } from 'react'
import { styled } from 'linaria/react'

export default ({ glyphs, initialWeight }) => {
  const [weight, setWeight] = useState(initialWeight)
  return (
    <>
      {/* <select
        defaultValue={weight}
        onChange={e => {
          setWeight(e.target.value)
        }}
      >
        <option value="normal">Normaal</option>
        <option value="bold">Vet</option>
      </select> */}
      <Grid style={{ fontWeight: weight }}>
        {glyphs.map(glyph => (
          <Tile key={glyph} data-glyph={glyph}>
            {glyph}
          </Tile>
        ))}
      </Grid>
    </>
  )
}

const tileSize = `--size-8`

const Grid = styled.ul`
  --tile-size: var(${tileSize});
  max-width: none;
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(var(--tile-size), 1fr));
  text-align: center;
  border-top: 1px solid var(--color-text);
  border-left: 1px solid var(--color-text);
`

const Tile = styled.li`
  --tile-size: var(${tileSize});
  height: var(--tile-size);
  font-size: 4rem;
  line-height: var(--tile-size);
  border-bottom: 1px solid var(--color-text);
  border-right: 1px solid var(--color-text);
  &:hover:after {
    content: attr(data-glyph);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    line-height: 100vh;
    pointer-events: none;
    @media (orientation: landscape) {
      font-size: 70vh;
    }
    @media (orientation: portrait) {
      font-size: 70vw;
    }
  }
`
