import React from 'react'
import { css, cx } from 'linaria'

import glyphs from '../data/Glyphs'
import Layout from '../components/Layout'
import String from '../components/String'
// import OTFeature from '../components/OTFeature'
import Multiline from '../components/Multiline'
import GlyphGrid from '../components/GlyphGrid'
import SEO from '../components/SEO'
import Prose, { proseStyle } from '../components/Prose'
import dd1 from '../static/dd1.jpg'
import dd2 from '../static/dd2.jpg'

export default () => (
  <Layout>
    <SEO />
    <header className={headerStyle}>
      <h1>Mercator</h1>
    </header>
    <main className="stack--xl">
      <Message />
      <Prose />
      <section>
        <div className="stack--xl">
          <String
            name="Mercator Normaal"
            weight="normal"
            initialSize={120}
            initialString="Stroopwafel Sans"
          />
          <String
            name="Mercator Vet"
            weight="bold"
            initialSize={160}
            initialString="Modernisme"
          />
          <String
            name="Mercator Normaal"
            weight="normal"
            initialSize={40}
            initialString="Tot aan 1960 stond de opleiding sterk onder invloed van de functionalistische en maatschappijkritische ideeën"
          />
        </div>
      </section>
      <section>
        <div className={sectionDivider}>
          {/* <h2>Talen</h2> */}
          <h2>Languages</h2>
        </div>
        <div className="container stack--s">
          <Multiline
            initialWeight="normal"
            initialSize={13}
            initialLineHeight={1.3}
            languages={[`nl`, `sv`, `pl`, `is`]}
          />
        </div>
      </section>
      {/* <section>
        <div className={sectionDivider}>
          <h2>Open Type Features</h2>
        </div>
        <div className="container stack--s">
          <OTFeature string="1234567890" feature="zero" highlights={[`0`]} />
          <OTFeature
            feature="case"
            highlights={[`-`, `—`, `!`, `?`, `¿`, `¡`, `{`, `}`]}
          />
          <OTFeature feature="smcp" highlights={[`nicef`]} />
          <OTFeature feature="c2sc" highlights={[`UNESCO`]} />
          <OTFeature feature="ordn" />
          <OTFeature feature="sups" highlights={[`0123456789`]} />
          <OTFeature feature="subs" highlights={[`0123456789`]} />
        </div>
      </section> */}
      <section>
        <div className={sectionDivider}>
          {/* <h2>Karakterset</h2> */}
          <h2>Characters</h2>
        </div>
        <div className="container">
          <GlyphGrid initialWeight="normal" glyphs={glyphs} />
        </div>
      </section>
    </main>
    <footer className={footerStyle}>
      <p>Theo van Beurden & Bauke van der Laan, {new Date().getFullYear()}</p>
    </footer>
  </Layout>
)

const footerStyle = css`
  margin: 20rem 0 4rem;
  font-size: var(--size-3);
  line-height: var(--size-4);
`

const headerStyle = css`
  border-bottom: 1px solid var(--color-accent);
  margin-top: 6rem;
  padding-right: 1rem;
`

const sectionDivider = css`
  border-bottom: 1px solid var(--color-text);
  margin-bottom: 6rem;
`

const messageHeading = css`
  color: var(--color-accent);
  margin-top: 0;
`

const messageStyle = css`
  background: #f1f1f1;
  padding: 1.5rem 2rem 1.5rem 1.5rem;
  max-width: 50rem;
  .images {
    margin: 3rem 2rem 0 0;
    display: flex;
    div:first-of-type {
      padding-right: 1.5rem;
    }
  }
`

const Message = () => (
  <div className={cx(proseStyle, messageStyle)}>
    <h2 className={messageHeading}>Update — July 2020</h2>
    <p>
      We are happy to share that our research in progress made its public debut
      in Dd, <a href="https://www.bno.nl/page/dd">the new magazine</a> by BNO
      (Association of Dutch Designers). The magazine is typeset almost
      exclusively in the revived typeface.
    </p>
    <p>
      Keep an eye on this website to stay up to date with the latest information
      on Mercator.
    </p>
    <div className="images">
      <div>
        <img src={dd1} alt="Cover of Dd magazine" />
      </div>
      <div>
        <img src={dd2} alt="Example of the layout of Dd magazine" />
      </div>
    </div>
  </div>
)
