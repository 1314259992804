import React, { useState } from 'react'
import { css } from 'linaria'

import RangeSlider from '../components/RangeSlider'

const stringStyle = css`
  position: relative;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap;
`

const description = css`
  font-size: 1.333rem;
  display: block;
  margin-bottom: var(--size-1);
`

export default ({ initialString, initialSize, weight, name }) => {
  const [size, setSize] = useState(initialSize)
  return (
    <div>
      <label className={description}>
        {name}, {size} pt.
      </label>
      <RangeSlider
        range={[8, 240]}
        value={size}
        onInput={(e) => {
          setSize(e.target.value)
        }}
      />
      <div
        contentEditable
        spellCheck="false"
        className={stringStyle}
        style={{
          fontSize: `${size}pt`,
          fontWeight: weight,
        }}
      >
        {initialString}
      </div>
    </div>
  )
}
