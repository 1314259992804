import React from 'react'
import { css } from 'linaria'
import specimen1 from '../static/specimen1.jpg'
import specimen2 from '../static/specimen2.jpg'

export const proseStyle = css`
  p {
    font-size: var(--size-3);
    line-height: var(--size-4);
    margin: 0;
    & + * {
      margin-top: var(--size-4);
    }
  }

  a {
    color: var(--color-accent);
    text-decoration: underline;
  }
  figure {
    margin: var(--size-6) 0;
  }

  figcaption {
    margin-top: var(--size-1);
    font-size: var(--size-2);
    line-height: var(--size-3);
  }
`

export default () => (
  <div className={proseStyle}>
    {/* <div className="container">
      <h2>Reviving a modern typeface</h2>
      <p>
        Bijna iedereen in de creatieve sector kent Helvetica en Univers, de
        alomtegenwoordige lettertypes die halverwege de 20ste eeuw in
        Zwitserland — bakermat van de modernistische vormgeving — werden
        ontworpen. Vormgevers als Wim Crouwel & Ben Bos introduceerden de
        modernistische vormgeving destijds in Nederland, met deze zogenaamde
        neo-grotesken in de hoofdrol.
      </p>
      <p>
        Ook in Nederland werd in de jaren 50 gewerkt aan een lettertype dat de
        modernistische tijdsgeest zou beantwoorden. Letterontwerper Dick Dooijes
        kreeg de opdracht een neutrale, schreefloze letter te ontwerpen. Die
        werd in 1958 — een jaar na Helvetica — uitgebracht als de Mercator en
        moest een alternatief voor de buitenlandse neo-grotesken worden. De
        letter verschijnt echter niet of nauwelijks in het (inter)nationale
        oeuvre van modernistische vormgevers en verdween samen met het hand- en
        machinezetsel van het toneel.
      </p>
      <figure>
        <img src={a} alt="Specimen van Mercator" />
      </figure>
      <p>
        In 2015 ontdekten ontwerpers Theo van Beurden en Bauke van der Laan een
        letterproef van de Mercator en begonnen ze aan het digitaliseren ervan.
        In eerste instantie om binnen de eigen praktijk te verkennen hoe de
        letter zich in een hedendaagse context zou gedragen. Tegelijkertijd
        groeide hun perceptie van de Mercator als een belangrijk onderdeel van
        het Nederlandse (typo)grafische erfgoed. Ze willen niet dat dit ontwerp
        zich tot een museumstuk beperkt, maar opnieuw een instrument wordt. Met
        dit project willen ze de Mercator nieuw leven inblazen d.m.v. een
        zogenaamde revival.
      </p>
      <figure>
        <img src={b} alt="Specimen van Mercator" />
      </figure>
    </div> */}
    <div className="container">
      <h2>Reviving a modern typeface</h2>
      <p>
        Almost everyone in the creative business is familiar with Helvetica and
        Univers, the ubiquitous typefaces that were designed in mid-20th century
        Switzerland — the birthplace of modernist graphic design. Dutch
        designers like Wim Crouwel & Ben Bos introduced modernist graphic design
        in the Netherlands in that era, giving these neo-grotesques a leading
        role.
      </p>
      <p>
        In the Netherlands too, a typeface was being made that would meet the
        modernist spirit of the times. Amsterdam Type Foundry commissioned type
        designer Dick Dooijes to design a neutral, sans-serif face. It was
        released in 1958 — a year after Helvetica — as Mercator and destined to
        become a viable alternative to the foreign neo-grotesques. However, the
        typeface did not or hardly appear in the oeuvre of modernist designers
        and quietly disappeared from the stage with the demise of manual and hot
        metal typesetting.
      </p>
      <figure>
        <img src={specimen1} alt="Mercator specimen" />
      </figure>
      <p>
        In 2015, designers Theo van Beurden and Bauke van der Laan decided to
        digitize the typeface after discovering a specimen of Mercator.
        Initially, to explore within their own practice how the typeface would
        work in a contemporary context. Simultaneously, their perception of
        Mercator as an important part of the Dutch typographic heritage, grew.
        They don’t accept that Mercator remains a museum artefact, but aim for
        the typeface to become a living instrument again. With this project they
        want to revive Mercator.
      </p>
      <figure>
        <img src={specimen2} alt="Mercator specimen" />
      </figure>
    </div>
  </div>
)
