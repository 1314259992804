import React from 'react'
import Helmet from 'react-helmet'

const title = `Mercator: reviving a modern typeface`
// const metaDescription = `Een project dat de mogelijke revival van de Nederlandse neo-grotesk Mercator onderzoekt`
const metaDescription = `A project that researches the possible revival of Dutch neo-grotesque Mercator`
const author = `@dogmaeu`
// const lang = `nl`
const lang = `en`

const SEO = () => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    meta={[
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ]}
  />
)

export default SEO
