import React from 'react'
import { css } from 'linaria'
import { styled } from 'linaria/react'

import { globalStyle } from '../globalStyle'

const breakpoints = [0, 800, 1200, 1600]

const StapleHoles = styled.div`
  --circle-size: var(--size-4);
  display: none;
  height: 100vh;
  width: var(--size-8);
  top: 0;
  left: 0;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  @media screen and (min-width: ${breakpoints[1]}px) and (min-height: 600px) {
    display: flex;
  }
  .staple {
    content: '';
    width: var(--circle-size);
    height: var(--circle-size);
    background: var(--color-text);
    border-radius: 100%;
    &:first-of-type {
      margin-bottom: 25vh;
    }
  }
`

const layoutStyle = css`
  padding-left: var(--size-3);
  /* max-width: 1400px;
  margin-left: auto;
  margin-right: 0; */
  @media screen and (min-width: ${breakpoints[1]}px) {
    padding-left: var(--size-8);
  }
  @media screen and (min-width: ${breakpoints[3]}px) {
    padding-left: calc((100vw - var(--content-max-width)) / 2);
  }
`

const Layout = ({ children }) => (
  <div className={globalStyle}>
    <StapleHoles>
      <div className="staple" />
      <div className="staple" />
    </StapleHoles>
    <div className={layoutStyle}>
      <div className="stack--xl">{children}</div>
    </div>
  </div>
)

export default Layout
